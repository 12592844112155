<template>
  <section id="inner-banner">
    <template>
      <v-container>
        <h1 v-html="title"></h1>
      </v-container>
    </template>
  </section>
</template>

<script>


export default {
  props: {
    title: String,
  },
  components: {
  },
  methods: {}
}
</script>
