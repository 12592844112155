<template>
  <div>
    <InnerBanner :title="pageData.productPage.pageTitle"></InnerBanner>
    <section class="product-page">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <h2>Licenses</h2>
            <div v-html="pageData.productPage.license"></div>
            <div class="performance" v-html="pageData.productPage.performance"></div>
            <div class="docs" v-html="pageData.productPage.docs"></div>
          </v-col>
          <v-col cols="12" sm="6" class="text-center align-self-center">
            <img :src="pageData.productPage.left_img" alt="why-bison">
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="product-third">
      <v-container>
        <h2>Functionality</h2>
        <v-row>
          <v-col cols="12" sm="6" v-html="pageData.productPage.functionalityLeftColumn"></v-col>
          <v-col cols="12" sm="6" v-html="pageData.productPage.functionalityRightColumn"></v-col>
        </v-row>
	<v-row v-html="pageData.productPage.functionalityBottom"></v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import InnerBanner from '../components/InnerBanner.vue'
import data from '../assets/page-data.json'

export default {
  components: {
    InnerBanner
  },
  data: function () {
    return {
      pageData: data,
    }
  },
  methods: {},
  mounted() {
  },
  metaInfo() {
    return {
      title: "Bison Router — a high-performance software router",
      meta: [
        {name: 'description', content: 'Bison Router is a high-performance software router for the broadband Internet Service Providers.'},
        {name: 'keywords', content: 'router, border network gateway, bng, bras, pppoe, ipoe, bgp, cg-nat, qos, dpdk, software router'},
        {property: 'og:title', content: "Bison Router — a high-performance software router"},
        {property: 'og:site_name', content: 'Bison Router — a high performance software router'},
        {property: 'og:type', content: 'website'}
      ]
    }
  }
}
</script>
