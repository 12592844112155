<template>
  <div>
    <section>
      <div class="container">
        <h1 class="text-center">404</h1>
        <h2>This page not found</h2>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
