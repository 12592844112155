<template>
  <div id="app">
          <header>
            <v-container>
              <v-row>
                <v-col md="2">
                  <a href="#" class="logo">
                    <img src="./assets/img/logo.svg" alt="">
                  </a>
                  <span id="menu-btn" class="svg-001-menu" :class="{active : menuOpen}" v-on:click="menuOpen = !menuOpen"></span>
                </v-col>
                <v-col cols="12" sm="6" id="main-menu" :class="{active : menuOpen}">
                  <ul>
                    <li v-for="(item,index) in pageData.menu.items" :key="index">
                      <router-link :to="item.link">{{ item.name }}</router-link>
                    </li>
                  </ul>
                </v-col>
                <v-col cols="12" sm="4" class="d-none d-sm-block">
                  <a v-on:click="hide=!hide" class="search" href="#"><img src="./assets/img/search.svg" alt=""></a>
                  <v-text-field v-if="hide" ref="name" placeholder="Search" required></v-text-field>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="lang" v-bind="attrs" v-on="on">
                        En
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in  pageData.languages" :key="index">
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <router-link to="/home/contact" class="btn-default">Try It Now</router-link>
                </v-col>
              </v-row>
            </v-container>
          </header>

          <router-view></router-view>

          <section class="footer">
            <template>
              <v-footer padless>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-card class="flex" flat tile>
                        <v-card-title>
                          <a href="#" class="logo"><img src="./assets/img/footer-logo.svg" alt=""></a>
                          <p>{{ new Date().getFullYear() }} © Bison Router. All rights reserved.</p>
                        </v-card-title>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" class="align-self-center">
                      <div class="links">
                        <a v-on:click.prevent="hideFooterSearch=!hideFooterSearch" href="#"><img src="./assets/img/search.svg" alt=""></a>
                        <v-text-field v-if="hideFooterSearch" ref="name" placeholder="Search" required></v-text-field>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">
                              En
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item v-for="(item, index) in pageData.languages" :key="index">
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <!-- <router-link to="/privacy">Privacy Policy</router-link> -->
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-footer>
            </template>
          </section>
  </div>
</template>

<script>
import data from './assets/page-data.json'
import router from './router/router'

export default {
  name: 'app',
  components: {
  },
  data: function () {
    return {
      tab: null,
      pageData: data,
      menuOpen: false,
      hide: false,
      hideFooterSearch: false,
    }
  },
  methods: {
    openMenu(){
      this.menuOpened = !this.menuOpened
    },
  },
  mounted() {
    var _this = this;
    router.afterEach(() => {
      _this.menuOpened = false;
    })
  }
}
</script>

<style>
@import url(assets/css/main.css);
</style>
