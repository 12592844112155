import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/Home.vue'
import Resources from '../pages/Home.vue'
import About from '../pages/Home.vue'
import Contact from '../pages/Home.vue'
import Product from '../pages/Product.vue'
import NotFound from '../pages/404.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/product',
        component: Product
    },
    {
        path: '/home/new-bread',
        component: Resources
    },
    {
        path: '/home/about',
        component: About
    },
    {
        path: '/home/contact',
        component: Contact
    },
    {
        path: '*', component: NotFound
    }
];

export default new VueRouter({
    routes,
    scrollBehavior: (to) => {
      if (to.path.split('/').length > 2) {
        const elmId = to.path.split('/').pop();
        setTimeout(function (){
          Vue.nextTick(() => {
            document.getElementById(elmId).scrollIntoView();
          })
        }, 50)
      }
    }
});
