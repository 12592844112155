import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router.js'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'

Vue.use(VueMeta)
Vue.use(VueGtag, {
  config: { id: "G-SZXSBLRGJE" }
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  vuetify,
  router,
  render: h => h(App)
})
